import React from "react";
import { useDrag } from "react-dnd";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const DraggableTag = ({ name, type }) => {
  const [{ isDragging }, drag] = useDrag({
    type,
    item: { name },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;

  return (
    <Box ref={drag} sx={{ opacity, cursor: "move", margin: 1 }}>
      <Card
        variant="outlined"
        sx={{ backgroundColor: isDragging ? "#f0f0f0" : "#ffffff" }}
      >
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {name}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DraggableTag;
