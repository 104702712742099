import React, { useEffect, useState } from "react";
import DraggableTag from "./DraggableTag";
import Category from "./Category";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

const TagDropContainer = ({ data, handleChange, answer }) => {
  const [tags, setTags] = useState(data.options);

  useEffect(() => {
    if(answer ==null){
      handleChange(data.categories.reduce((acc, category) => {
        acc[category] = [];
        return acc;
      }, {}));
      setTags(data.options);
    }
  }, [answer,data.categories, data.options ]);

  const handleDrop = (category, item) => {
    handleChange((prevCategories) => {
      const updatedCategories = { ...prevCategories };
      for (let key in updatedCategories) {
        updatedCategories[key] = updatedCategories[key].filter(
          (tag) => tag !== item.name
        );
      }
      updatedCategories[category].push(item.name);
      return updatedCategories;
    });
    setTags((prevTags) => prevTags.filter((tag) => tag !== item.name));
  };

  const handleTagRemoval = (category, item) => {
    handleChange((prevCategories) => {
      const updatedCategories = { ...prevCategories };

      // Remove the tag from the category
      updatedCategories[category] = updatedCategories[category].filter(
        (tag) => tag !== item.name
      );

      return updatedCategories;
    });

    setTags((prevTags) => [...prevTags, item.name]);
  };


  return (

      <DndProvider backend={HTML5Backend}>
    <Box sx={{ padding: 3 }}>
      <Grid container spacing={3} justifyContent="space-around">
        {data.categories.map((category) => (
            <Category
              accept="TAG"
              onDrop={(item) => handleDrop(category, item)}
              name={category}
            >
              {answer  &&  answer[category].map((tag, index) => (
                  <DraggableTag
                      key={index}
                      name={tag}
                      type="TAG"
                      onRemove={handleTagRemoval}
                  />
              ))}
            </Category>
        ))}
      </Grid>

      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h6" gutterBottom>
          Answers
        </Typography>
        <Paper sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            {tags.map((tag, index) => (
              <Grid item key={index}>
                <DraggableTag name={tag} type="TAG" />
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Box>
    </Box>
      </DndProvider>
  );
};

export default TagDropContainer;
