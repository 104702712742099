import React, {useEffect, useState} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Option from './Option';
import { Box, Paper } from '@mui/material';

const FlowFix = ({ questionData, handleChange ,answer}) => {


    useEffect(()=> {
        if(answer===null) {
            handleChange(questionData.options)
        }
    },[questionData.options,answer])
    const moveOption = (dragIndex, hoverIndex) => {
        handleChange((prevOptions) => {
            const updatedOptions = [...prevOptions];
            const draggedOption = updatedOptions[dragIndex];
            updatedOptions.splice(dragIndex, 1);
            updatedOptions.splice(hoverIndex, 0, draggedOption);
            return updatedOptions;
        });
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Box sx={{ padding: 4 }}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                    <ul style={{ padding: 0 }}>
                        {answer && answer.map((option, index) => (
                            <Option key={index} index={index} option={option} moveOption={moveOption} />
                        ))}
                    </ul>
                </Paper>
            </Box>
        </DndProvider>
    );
};

export default FlowFix;
