import React from "react";
import { Helmet } from "react-helmet";
import {useNavigate} from "react-router-dom";

export default function NotFoundPage() {
    const navigate = useNavigate();
    setTimeout(() => {
        navigate("/");
    }, 5000);
  return (
    <main>
      <Helmet>
        <title>Not Found! | Tech Excellence - NexTuple Inc</title>
      </Helmet>
      <p>No such page exists! Will redirect to the events page in 5 seconds...</p>
    </main>
  );
}