import {useCallback, useEffect, useState} from 'react';
import {
    doc,
    getDoc,
    serverTimestamp,
    setDoc
} from 'firebase/firestore';
import {decryptMessage} from './cryptoUtil.js';
import {db} from './firebase.js';
import {useSelector} from 'react-redux';

const fetchEventData = async () => {
    try {
        const eventRef = doc(db, 'events', 'events');
        const eventQuery = await getDoc(eventRef);

        if (!eventQuery.exists()) {
            throw new Error('Events does not exist');
        }

        const data = eventQuery.data();

        const eventDataWithEncrypt=Object.keys(data).map(key => ({
            id: key,
            ...data[key],
        })).sort((a, b) => Number(a.sid) - Number(b.sid));
        eventDataWithEncrypt.forEach((event,index) => {
            eventDataWithEncrypt[index].questions = event.questions.map(question => {
                const decryptedQuestion = question?.question ? JSON.parse(decryptMessage(question.question)) : null;
                const decryptedCode = question?.code ? JSON.parse(decryptMessage(question.code)) : null;
                const decryptedOptions = question?.options ? JSON.parse(decryptMessage(question.options)) : null;
                const decryptedCategories = question?.categories ? JSON.parse(decryptMessage(question.categories)) : null;
                const decryptedAnswer = question?.answers ? JSON.parse(decryptMessage(question.answers)) : null;
                return {
                    ...question,
                    question: decryptedQuestion,
                    code: decryptedCode,
                    options: decryptedOptions,
                    categories: decryptedCategories,
                    answers: decryptedAnswer
                };
            });
        })
        return eventDataWithEncrypt;
    } catch (error) {
        throw error;
    }
};

export const useEventData = () => {
    const [liveEvents, setLiveEvents] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [doneEvents, setDoneEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchEvents = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const docs = await fetchEventData();
                const live = docs.filter(event => event.live).sort((a, b) => Number(a.sid) - Number(b.sid));
                const upcoming = docs.filter(event => !event.live && !event.done).sort((a, b) => Number(a.sid) - Number(b.sid));
                const done = docs.filter(event => event.done).sort((a, b) => Number(a.sid) - Number(b.sid));
                setLiveEvents(live);
                setUpcomingEvents(upcoming);
                setDoneEvents(done);
            } catch (err) {
                setError(err.message || 'An error occurred');
                console.error(err);
            }
            setIsLoading(false);
        };

        fetchEvents();
    }, []);

    return [liveEvents, upcomingEvents, doneEvents, isLoading, error];
};

export const useEventQuestions = (eventId) => {
    const [event, setEvent] = useState(null);
    const [userAnswers, setUserAnswers] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const user = useSelector((state) => state.user);

    const fetchEvent = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const docs = await fetchEventData();
            const event = docs.find(event => event.id === eventId);
            setEvent(event || null);
        } catch (err) {
            setError(err.message || 'An error occurred');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }, [eventId]);

    const fetchUser = useCallback(async (userEmail) => {
        setIsLoading(true);
        setError(null);
        try {
            const userRef = doc(db, 'users', userEmail);
            const userDoc = await getDoc(userRef);

            if (userDoc.exists()) {
                const userAnswers = userDoc.data();
                setUserAnswers(userAnswers);
                return userAnswers;
            }

            return null;
        } catch (err) {
            setError(err.message || 'An error occurred');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchEvent();
    }, [fetchEvent]);

    useEffect(() => {
        if (user) {
            fetchUser(user.email);
        }
    }, [fetchUser, user]);

    const submitAnswer = useCallback(async (qid, answer, stopWatch) => {
        setIsLoading(true);
        setError(null);
        try {
            if (user && user.email) {
                const docs = await fetchEventData();
                const event = docs.find(event => event.id === eventId);
                setEvent(event || null);

                if (!event || !event.accept) {
                    throw new Error('Event is closed for submissions');
                }

                if (userAnswers && userAnswers[`${eventId}:${qid}`]) {
                    throw new Error('Answer already submitted for this question');
                }

                await setDoc(doc(db, 'users', user.email), {
                    [`${eventId}:${qid}`]: {
                        qid: qid,
                        time: serverTimestamp(),
                        answer: answer,
                        stopWatch: stopWatch
                    }
                }, {merge: true});
                return true;
            } else {
                throw new Error('User not logged in');
            }
        } catch (err) {
            setError(err.message || 'An error occurred');
            console.error(err);
            return false;
        } finally {
            setIsLoading(false);
        }
    }, [user, userAnswers, eventId]);

    return [event, isLoading, error, submitAnswer, userAnswers];
};


function formatName(input) {
    let parts = input.split('.');
    let formattedParts = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase());
    return formattedParts.join(' ');
}
export const useLeaderboard = () => {
    const [row, setRow] = useState({});
    const [column, setColumn] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchLeaderboardAndEvents = async () => {
            setLoading(true);
            try {
                // Fetch leaderboard
                const leaderboardRef = doc(db, 'leaderboard', 'leaderboard');
                const leaderboardDoc = await getDoc(leaderboardRef);
                const leaderboardData = leaderboardDoc.data() || {};

                // Fetch events
                const eventsDoc = await fetchEventData();
                const eventsData = eventsDoc.reduce((acc, event) => {
                    acc[event.id] = event;
                    return acc;
                }, {});

                const sortedEventKeys = Object.keys(eventsData)
                    .sort((a, b) => Number(eventsData[b].sid) - Number(eventsData[a].sid))
                    .filter(eventId => eventsData[eventId].showKey);

                // Initialize columns
                const columns = [
                    { field: 'userId', headerName: 'User ID', width: 400, sortable: false },
                    { field: 'totalScore', headerName: 'Total Score', flex:1, sortable: true },
                    ...sortedEventKeys.map(eventId => ({
                        field: eventId,
                        headerName: eventsData[eventId].date,
                        flex:1,
                        sortable: true,
                    }))
                ];

                setColumn(columns);
                const rows={};
                Object.keys(leaderboardData).forEach(eventId => {
                    const eventLeaderboard = leaderboardData[eventId];
                    Object.keys(eventLeaderboard).forEach(userId => {
                        const score = eventLeaderboard[userId];
                        if (!rows[userId]) {
                            rows[userId] = { id:userId, userId, totalScore: 0 };
                        }
                        rows[userId][eventId] = score;
                        rows[userId].totalScore += score;
                    });
                });

                Object.keys(rows).forEach(userId => {
                    rows[userId].userId = formatName(userId.split('@')[0]);
                    rows[userId].totalScore = Number(rows[userId].totalScore).toFixed(2);
                });

                setRow(Object.values(rows).sort((a, b) => b.totalScore - a.totalScore));

            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchLeaderboardAndEvents();
    }, []);

    return { row, column, loading, error };
};

export const useAnswer = (eventId) => {
    const user = useSelector((state) => state.user);
    const [event, setEvent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchEvent = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const docs = await fetchEventData();
                const fetchedEvent = docs.find(event => event.id === eventId);
                if (fetchedEvent) {
                    const userRef = doc(db, 'users', user.email);
                    const userDoc = await getDoc(userRef);
                    let userAnswers;
                    if(userDoc.exists()){
                        userAnswers = userDoc.data();
                    }else{
                        userAnswers = null;
                    }
                    fetchedEvent.questions = fetchedEvent.questions.map(question => {
                        const key = `${eventId}:${question.sid}`;
                        return {
                            ...question,
                            response: (userAnswers && userAnswers.hasOwnProperty(key)) ? userAnswers[key] : null
                        };
                    }).sort((a, b) => a.sid - b.sid);
                    setEvent(fetchedEvent);
                }
            } catch (err) {
                setError(err.message || 'An error occurred');
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchEvent();
    }, []);

    return [event, isLoading, error];
}