import {Provider} from "react-redux";
import {
    HashRouter as Router,
} from "react-router-dom";
import "./App.css";
import store from './data/store';
import HomePage from "./HomePage";

function App() {
    return (
            <Router>
                <Provider store={store}>
                    <HomePage/>
                </Provider>
            </Router>
    );
}

export default App;
