import React, {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {getAuth, signOut} from "firebase/auth";

import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Container,
    Avatar,
    Button,
    Tooltip,
    MenuItem,
    Divider,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
function formatName(input) {
    let parts = input.split('.');
    let formattedParts = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase());
    return formattedParts.join(' ');
}
export default function TopNav({user, logout}) {
    const pages = [
        {label: "Event", value: ""},
        {label: "Leaderboard", value: "leaderboard"},
    ];
    const [linkValue, setValue] = useState("user");
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        let path = location.pathname;
        if (path[0] == "/") path = path.slice(1);
        if (path[path.length - 1] == "/") path = path.slice(0, -1);
        setValue(path);
    }, [location.pathname]);

    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const openLink = (value) => {
        navigate(value);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        handleCloseUserMenu();
        const authInstance = getAuth();
        signOut(authInstance)
            .then(() => {
                console.log("signed out successfully");
                // setIsLoading(false);
            })
            .catch((error) => {
                // setErrorMessage("Something went wrong. Please try logging out again.");
                console.error(error);
                // setIsLoading(false);
            });
    };

    return (
        <AppBar
            position="sticky"
            sx={{
                width: "100%",
                zIndex: 1000,
                bgcolor: "#19384f",
            }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img
                        style={{maxHeight: "32px", marginRight: "10px"}}
                        alt="Logo of Nextuple"
                        src={process.env.PUBLIC_URL + "/nextuple.webp"}
                    />
                    <Box sx={{flexGrow: 1, display: "flex"}}>
                            <>
                                <Divider
                                    orientation="vertical"
                                    variant="middle"
                                    flexItem
                                    sx={{bgcolor: "#fff", mr: 2}}
                                />
                                {pages.map(({label, value}) => (
                                    <Button
                                        key={value}
                                        data-testid={"nav-bar-" + value}
                                        onClick={() => openLink(value)}
                                        sx={{
                                            my: 2,
                                            color:
                                                linkValue === value
                                                    ? "#aaa !important"
                                                    : "#fff !important",
                                            display: "block",
                                        }}
                                    >
                                        {label}
                                    </Button>
                                ))}
                            </>
                    </Box>

                    <Box sx={{flexGrow: 0}}>
                        {user != null && (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        display: "flex",
                                        mr: 3,
                                    }}
                                >
                                    <b>{user && formatName(user.email.split('@')[0])}</b>
                                </Box>
                                <Tooltip title="Account">
                                    <IconButton
                                        data-testid="handleOpenUserMenu"
                                        onClick={handleOpenUserMenu}
                                        sx={{p: 0}}
                                    >
                                        <Avatar
                                            sx={{
                                                bgcolor: "#b72467",
                                            }}
                                        >
                                            <PersonIcon/>
                                        </Avatar>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                        <Menu
                            sx={{mt: "45px"}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {user != null && user.email &&
                                <MenuItem sx={{pointerEvents: "none"}}>
                                    <Typography textAlign="center">
                                        Username: {formatName(user.email.split('@')[0])}
                                    </Typography>
                                </MenuItem>
                            }
                            <MenuItem onClick={handleLogout} data-testid="logout">
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
