import TextField from "@mui/material/TextField";
import {Autocomplete, Badge, Box, Button, IconButton, Popover, styled, Tooltip, Typography} from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React, {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";


export default function CodeQuiz({question, answer, handleChange}) {
    useEffect(() => {
        if (answer == null) {
            const answer = {};
            const noOfLines = question.code.length;
            for (let i = 0; i < noOfLines; i++) {
                answer[i.toString()] = [];
            }
            handleChange(answer);
        }
    }, [question, answer]);
    return (<Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: "#eee",
            mt: 3,
            mb: 3,
            boxSizing: 'border-box',
            padding: "10px",
            borderRadius: "20px",
        }}>
            {question && question?.code.map((line, index) => (
                <Box key={index} sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                }}>
                    <Box>
                        <AddTags
                            index={index}
                            handleChange={(value) => {
                                const newAnswer = {...answer};
                                newAnswer[index.toString()] = value;
                                handleChange(newAnswer);
                            }} answer={answer && answer[index.toString()]} options={question.options} line={line}/>
                    </Box>
                    <Box sx={{
                        width: '100%',
                        backgroundColor: '#fff',
                        border: '2px solid #fff',
                        padding: (line && line.trim()!==""?0.5: 1),
                        borderRadius: (index === 0 ? '10px 10px 0px 0px' : (index === question.code.length - 1 ? '0px 0px 10px 10px' : '0px'))
                    }} align="left">
                        <Typography variant={'h5'}><span dangerouslySetInnerHTML={{__html: line}}/></Typography>
                    </Box>
                </Box>
            ))}
        </Box>
    )
}

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -4,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const AddTags = ({index, handleChange, answer, options,line}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [error, setError] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setAnchorEl(null);
    };

    return (
        <>

            <IconButton onClick={handleClick} sx={{minWidth:"40px"}}>
                <Tooltip title={(answer && answer.length > 0) ? answer.join(", ") : "Add Tags"}  placement="top">
                    <StyledBadge badgeContent={(answer && answer.length)} color="primary">
                        {
                            line && line.trim()!=="" &&
                        <AddCircleIcon/>
                        }
                    </StyledBadge>
                </Tooltip>
            </IconButton>
            <Popover
                id={`popover-${index}`}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Card sx={{width: '90vw', maxWidth: '500px', margin: '0px', padding: '5px'}}>
                    <CardContent>
                        <h2>Add Tags</h2>
                        {error && <Typography color="error">{error}</Typography>}
                        <br/>
                        <Autocomplete
                            value={answer}
                            onChange={(e, selectedObject) => {
                                if (selectedObject !== null) {
                                    if (selectedObject.length > 2) {
                                        setError("You can select maximum 2 tags");
                                        return;
                                    } else {
                                        setError(null);
                                    }
                                    handleChange(selectedObject);
                                }
                            }}
                            multiple
                            fullWidth
                            limitTags={2}
                            id="multiple-limit-tags"
                            options={options}
                            noOptionsText="Press enter to add new theme"
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        label="Tags"
                                        placeholder="Add tags"
                                        pattern="[a-z0-9]+"
                                    />
                                );
                            }}
                        />
                    </CardContent>
                </Card>
            </Popover>

        </>
    );
};
