import {useDispatch, useSelector} from "react-redux";
import {
    Navigate,
    Route,
    Routes, useLocation
} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import "./App.css";
import {useEffect} from "react";
import TopNav from "./component/TopNav";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "./data/firebase.js";
import {setUser} from './data/store';
import AccountPage from "./pages/AccountPage";
import NotFoundPage from "./pages/NotFoundPage";
import EventsPage from "./pages/EventsPage";
import {GLOBALS} from "./GLOBALS";
import Quiz from "./pages/Quiz";
import Leaderboard from "./pages/Leaderboard";
import Answers from "./pages/Answers";


function Check({bAuth, element}) {
    const location = useLocation();
    const user = useSelector((state) => state.user) !== null;
    if(!user){
        return <Navigate to="/user" replace state={{ from: location?.pathname || "/" }} />;
    }
    return element;
}


function HomePage() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const unsubscribeAuth = onAuthStateChanged(auth, async (users) => {
                    if (users) {
                        const userData = {
                            uid: users?.uid,
                            email: users?.email,
                            displayName: users?.displayName,
                            lastLoginAt: users?.metadata?.lastLoginAt,
                            lastRefreshAt: users?.reloadUserInfo?.lastRefreshAt
                        }
                        dispatch(setUser(userData));
                    } else {
                        dispatch(setUser(null));
                    }
                });

                return () => {
                    unsubscribeAuth();
                };
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };
        fetchData();
    }, [auth]);

    const theme = createTheme({
        palette: {
            primary: {
                main: GLOBALS.primaryColor,
            },
            secondary: {
                main: GLOBALS.secondaryColor,
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <TopNav user={user}/>
            <div className="main">
                <div className="complete">
                    <Routes>
                        {/*Account*/}
                        <Route exact path="/user" element={<AccountPage user={user}/>}/>
                        {/*Event*/}
                        <Route path="/" element={<Check element={<EventsPage/>}/>}/>
                        {/*Leaderboard*/}
                        <Route path="/leaderboard" element={<Check element={<Leaderboard/>}/>}/>
                        {/*Quiz*/}
                        <Route path="/quiz/:id" element={<Check element={<Quiz/>}/>}/>
                        {/*Anwsers*/}
                        <Route path="/answers/:id" element={<Check element={<Answers/>}/>}/>
                        {/*Not Found*/}
                        <Route path="*" element={<Check element={<NotFoundPage/>}/>}/>
                    </Routes>
                </div>
            </div>
        </ThemeProvider>
    );
}

export default HomePage;
