import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Card, CardContent, Typography } from '@mui/material';

const Option = ({ option, index, moveOption }) => {
    const ref = React.useRef(null);

    const [, drop] = useDrop({
        accept: 'OPTION',
        hover(item) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            moveOption(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'OPTION',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <li ref={ref} style={{ opacity: isDragging ? 0.5 : 1, listStyle: 'none', marginBottom: '10px' }}>
            <Card sx={{ backgroundColor: isDragging ? '#f5f5f5' : '#fff', cursor: 'move', transition: 'background-color 0.2s ease',textAlign:'left' }}>
                <CardContent>
                    <Typography variant="body1">
                        {option}
                    </Typography>
                </CardContent>
            </Card>
        </li>
    );
};

export default Option;
