//store.js
import { configureStore, createSlice, createAsyncThunk, nanoid } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: null,
    reducers: {
        setUser: (state, action) => {
            return action.payload;
        },
    },
});
const userReducer = userSlice.reducer;
const locationSlice = createSlice({
    name: 'location',
    initialState: {
        path: '/',
    },
    reducers: {
        setPath: (state, action) => {
            state.path = action.payload;
        },
    },
});

const locationReducer = locationSlice.reducer;
const store = configureStore({
    reducer: {
        user: userReducer,
        location: locationReducer,
    }
});

export const { setUser } = userSlice.actions;
export const { setPath } = locationSlice.actions;
export default store;
