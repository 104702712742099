import React, {useState} from "react";
import { Helmet } from "react-helmet";
import {useSelector} from "react-redux";
import {useEventData} from "../data/firebaseHooks";
import {Box, Typography, Button, Alert, Chip} from '@mui/material';
import {Schedule, CheckCircle} from '@mui/icons-material';
import {GLOBALS} from "../GLOBALS"
import "../App.css"
import * as PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
export default function EventsPage() {
    return (
        <main>
            <Helmet>
                <title>Events | Tech Excellence - NexTuple Inc</title>
            </Helmet>
            <EventsList/>
        </main>
    );
}

function EventsList() {
    const [liveEvents, upcomingEvents, doneEvents, isLoadingEvents, errorEvents] = useEventData();
    return (
        <div>
            {errorEvents && <Alert severity="error">{errorEvents}</Alert>}
            <h1>Events</h1>
            {isLoadingEvents && <p>Loading...</p>}
            {liveEvents && liveEvents.length > 0 && (
                <Box sx={{m: "20px 0px"}}>
                    <h2 style={{color: GLOBALS.primaryColor}}>Live Events</h2>
                    {liveEvents.map((event) => (
                        <Event key={event?.id} data={event}/>
                    ))}
                </Box>
            )}
            {upcomingEvents && upcomingEvents.length > 0 && (
                <Box sx={{m:"20px 0px"}}>
                    <h2 style={{color:GLOBALS.primaryColor}}>Upcoming Events</h2>
                    {upcomingEvents.map((event) => (
                        <Event key={event?.id} data={event}/>
                    ))}
                </Box>
            )}
            {doneEvents && doneEvents.length > 0 && (
                <Box sx={{m:"20px 0px"}}>
                    <h2 style={{color:GLOBALS.primaryColor}}>Done Events</h2>
                    {doneEvents.map((event) => (
                        <Event key={event?.id} data={event}/>
                    ))}
                </Box>
            )}
        </div>
    );
}


function CheckCircleIcon(props) {
    return null;
}

CheckCircleIcon.propTypes = {
    color: PropTypes.string,
    sx: PropTypes.shape({mr: PropTypes.number, fontSize: PropTypes.number})
};
const Event = ({ data }) => {
    const user = useSelector((state) => state.user);
    const {
        id,
        sid,
        topic,
        speaker,
        date,
        event,
        maxPoints,
        accept,
        showKey,
        live,
        done
    } = data;

    const navigate = useNavigate();
    const handleStartClick = () => {
        navigate(`/quiz/${id}`);
    };

    return (
        <Box sx={{ borderRadius: 3, padding: 2, m: 3, display: 'flex', flexDirection: 'row', boxShadow:3, width: "85vw"}}>
            <Box sx={{ flex: 3, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:"flex-start", mr: 2 }}>
                <Box>
                    {live ? <div className="pulsating-circle" style={{margin:"15px 20px 15px 15px"}}/>: (done ? <CheckCircle color="success" sx={{ fontSize: 40, mr:1 }} /> :
                        <Schedule color="action" sx={{fontSize: 30, m:"10px" }} />)}
                </Box>
                <Box sx={{ mr: 2 }}>
                    <Typography variant="h5" align="left" sx={{color: GLOBALS.primaryColor}}>{topic}</Typography>
                    <Typography variant="h6" align="left" >{speaker}</Typography>
                    <Typography variant="subtitle1" align="left">Date: {date}</Typography>
                </Box>
            </Box>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Box sx={{ mb: 1 }}>
                        {accept && (
                            <Button variant="contained" color="primary" sx={{ mb: 1 }} onClick={handleStartClick}>
                                Start {event}
                            </Button>
                        )}
                        {showKey && (
                            <Button variant="outlined" color="secondary" sx={{ mb: 1 }} onClick={() => navigate(`/answers/${id}`)}>
                                Show Results
                            </Button>
                        )}
                        {!accept && !showKey && (
                            <Chip label={<Typography variant="subtitle2">{event}</Typography>} />
                        )}
                    </Box>
                    <Box>
                        <Typography variant="subtitle2">Max Points: {maxPoints}</Typography>
                    </Box>
            </Box>
        </Box>
    );
};
