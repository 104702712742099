import React from "react";
import { useDrop } from "react-dnd";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Category = ({ accept, onDrop, name, children }) => {
  const [{ isOver }, drop] = useDrop({
    accept,
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const backgroundColor = isOver ? "#f0f0f0" : "white";

  return (
    <Paper
      ref={drop}
      elevation={3}
      sx={{
        backgroundColor,
        padding: 2,
        minHeight: "200px",
        minWidth: { xs: "100%", sm: "250px", md: "300px" }, // Fixed width for larger screens
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "8px",
        boxSizing: "border-box",
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        {name}
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
    </Paper>
  );
};

export default Category;
