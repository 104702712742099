import {Box, Chip, CircularProgress, Typography} from "@mui/material";
import {GLOBALS} from "../GLOBALS";
import {useParams} from "react-router-dom";
import {useAnswer} from "../data/firebaseHooks";
import Alert from "@mui/material/Alert";
import {MarkdownRenderer} from "./Quiz";
import React from "react";
import {Helmet} from "react-helmet";

export default function Answers() {
    const {id} = useParams();
    const [event, isLoading, error] = useAnswer(id);

    if (event && !event.showKey) {
        return <Typography variant="h4" sx={{color: GLOBALS.primaryColor}} align="center">Answers for this event are not
            available yet.</Typography>
    }
    return (
        <Box sx={{width: "80vw", maxWidth: "1000px", margin: "auto", mt: 3}}>
            <Helmet>
                <title>Answers | Tech Excellence - NexTuple Inc</title>
            </Helmet>
            {isLoading && <CircularProgress/>}
            {error && <Alert severity={"error"}>{error.message}</Alert>}
            {event && <Box sx={{textAlign: "left", mb: 3}}>
                <Typography variant="h5" sx={{color: GLOBALS.primaryColor}}
                            align="center">{event.sid}. {event.topic} ({event.event})</Typography>
                <Typography variant="h6" align="center">{event.speaker} ({event.date})</Typography>
                <Typography variant="subtitle1">Scored {Number(event.questions.reduce((acc, question) => {
                    return acc + ((question.response && question.response.score) ? Number(question.response.score) : 0);
                }, 0)).toFixed(2)} out of {event.maxPoints}</Typography>
            </Box>}

            {event && event.questions.map((questionStr, index) => (
                <Box key={index} sx={{mb: 3, textAlign: "left"}}>
                    <MarkdownRenderer content={questionStr.sid + ". " + questionStr.question}/>
                    <ResponseDisplay question={questionStr}/>
                </Box>
            ))}

        </Box>
    );
};

const ResponseDisplay = ({question}) => {
    const response = question.response;
    let content = null;
    switch (question.type) {
        case "MCQ":
            content = <>
                {(response && response.answer) &&
                    <Typography variant="subtitle1">Your Response: {response.answer.join(", ")}</Typography>}
                <Typography variant="subtitle1">Correct Answer: {question.answers.join(", ")}</Typography>
            </>
            break;
        case "ODD-OUT":
            content = <>
                {(response && response.answer) &&
                    <Typography variant="subtitle1">Your Response: {response.answer}</Typography>}
                <Typography variant="subtitle1">Correct Answer: {question.answers}</Typography>
            </>
            break;
        case "PIXEL":
        case "TREASURE":
            content = <>
                {(response && response.answer) &&
                    <Typography variant="subtitle1">Your Response: {response.answer}</Typography>}
                <Typography variant="subtitle1">Correct Answer: {question.answers.join(", ")}</Typography>
            </>
            break;
        case "CATEGORIZATION":
            content = <>
                {response && response.answer && <> <Typography variant="subtitle1">Your Response: </Typography>
                    <dl style={{paddingLeft: "50px"}}>
                        {response.answer && Object.keys(response.answer).map((categoryKey) => (
                            <>
                                <dt><b>{categoryKey}</b>: {response.answer[categoryKey].join(", ")}</dt>
                            </>
                        ))}
                    </dl>
                </>
                }
                <Typography variant="subtitle1">Correct Answer: </Typography>
                {question && question.answers && Object.keys(question.answers).map((categoryKey) => (
                    <>
                        <dl style={{paddingLeft: "50px"}}>
                            <dt><b>{categoryKey}</b>: {question.answers[categoryKey].join(", ")}</dt>
                        </dl>
                    </>
                ))}
            </>
            break;
        case "CODE":
            content = <>
                { question && question.code && question.code.map((line, index) => <>
                            <Typography variant="h6"><span dangerouslySetInnerHTML={{__html: line}}/></Typography>
                            {question.answers && question.answers[index.toString()] &&
                                question.answers[index.toString()].length > 0 &&
                                <Chip sx={{mr: 2, mb: 0.5}}
                                      label={"Correct tags: " + question.answers[index.toString()].join(", ")} size="small"
                                      color="success"/>
                            }
                            {response && response.answer &&
                                response.answer[index.toString()].length > 0 &&
                                <Chip sx={{mb: 1}} label={"Your Responses: " + response.answer[index.toString()].join(", ")}
                                      size="small" color="info"/>
                            }
                        </>
                    )
                }
            </>
            break;
        case "FLOW-FIX":
            content = <>
                {(response && response.answer) && <Typography variant="subtitle1">Your Response: <ol
                    style={{paddingLeft: '50px'}}>{response.answer.map((answer) => <li>{answer}</li>)}</ol>
                </Typography>}
                <Typography variant="subtitle1">Correct Answer: <ol
                    style={{paddingLeft: '50px'}}>{question.answers.map((answer) => <li>{answer}</li>)}</ol>
                </Typography>
            </>
            break;

        default:
            content = null;
    }
    return (
        <Box sx={{mt: 2}}>
            {content}
            {(response && response.answer) ?
                <Typography variant="subtitle2">scored {response.score} out
                    of {question.points}</Typography> :
                <Typography variant="subtitle2">Not Attempted</Typography>}
        </Box>
    );
}